import React from 'react';

export default function useOutsideClick<T extends HTMLDivElement = HTMLDivElement>(callback: () => void) {
    const ref = React.useRef<T | null>(null);

    React.useEffect(() => {
        const handleClick = (event: Event) => {
            const target = event.target;

            if (!(target instanceof Node)) {
                return;
            }

            if (ref.current && !ref.current.contains(target)) {
                callback();
            }
        };

        // set up in capture phase to insure outside click will always work
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [callback]);

    return ref;
}
